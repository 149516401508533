import { Link } from "gatsby";
import * as React from "react";
import { useMediaQuery } from "@react-hook/media-query";

import * as styles from "./navigation.module.scss";
import { Squash as Hamburger } from "hamburger-react";

const Navigation = () => {
  let [showMenu, setShowMenu] = React.useState(false);

  const clickHandler = () => {
    setShowMenu(false);
  };

  const smallScreen = useMediaQuery("(max-width: 800px)");

  const navLinks = [
    <Link to={"/gallery"} onClick={clickHandler}>Gallery</Link>,
    <Link to={"/blog"} onClick={clickHandler}>Blog</Link>,
    <Link to={"/about"} onClick={clickHandler}>About</Link>,
  ];

  return (
    <>
      <div className={styles.topBar + " " + (showMenu && smallScreen ? styles.fsNavExpanded : "")}>
        <nav>
          <Link to={"/"} className={styles.logo}>
            1in9.net
          </Link>
          {smallScreen ? (
            <>
              <Hamburger toggled={showMenu} toggle={setShowMenu}></Hamburger>
            </>
          ) : (
            <>{navLinks}</>
          )}
        </nav>
        {smallScreen && (
            <>
              <div className={styles.fsNav}>
                <div className={styles.mInner}>
                  <span>Menu</span>
                  {navLinks}
                </div>
              </div>
            </>
          )}
      </div>
    </>
  );
};

/*const query = graphql`

`*/

export default Navigation;
