import * as React from "react";
import Layout from "../../layout/default";
import { MDXRenderer } from "gatsby-plugin-mdx";

import * as styles from "./index.module.scss";
import { graphql } from "gatsby";

const AboutIndexPage = ({ data }) => {
  return (
    <Layout>
      <div className={styles.layoutBox}>
        <MDXRenderer title="My Stuff!">
          {data.file.childrenMdx[0].body}
        </MDXRenderer>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    file(relativePath: { eq: "about.mdx" }) {
      childrenMdx {
        body
      }
    }
  }
`;

export default AboutIndexPage;
