import * as React from "react";
import { Link } from "gatsby";
import LayoutDefault from "../layout/default";

import * as styles from "./index.module.scss";

const NotFoundPage = () => {
  return (
    <LayoutDefault>
      <div className={styles.layoutBox}>
        <h1>404 - Page not found</h1>
        <p>
          The requested page could not be found. Please check your spelling or
          go <Link to={"/"}>home</Link>.
        </p>
      </div>
    </LayoutDefault>
  );
};

export default NotFoundPage;
