import * as React from "react";

import * as styles from "./index.module.scss";

import Layout from "../layout/default";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const IndexPage = () => {
  return (
    <Layout>
      {typeof window === "undefined" && <noscript className={styles.noScriptNotice}>
        Hey, I've put a lot of work into this website, and I would like you to experience it like I wanted it to be experienced. Unfortunately you have JavaScript disabled. Please allow first-party-scripts and allow me to show you what I've created!
      </noscript>}
      <figure className={styles.landingHero}>
        <StaticImage
          src={"../images/landing-hero-01.jpg"}
          width={600}
          className={styles.floatyImage1}
          placeholder="blurred"
          alt="1in9.net Midnight Gallery Night Image"
        ></StaticImage>
        <StaticImage
          src={"../images/landing-hero-02.jpg"}
          width={600}
          className={styles.floatyImage2}
          placeholder="blurred"
          alt="1in9.net Midnight Gallery Night Image"
        ></StaticImage>
        <div className={styles.heroTextBox}>
          <StaticImage
            src={"../images/landing-hero-01.jpg"}
            height={500}
            className={styles.heroTextBoxBg}
            placeholder="blurred"
            alt="1in9.net Midnight Gallery Night Image"
          ></StaticImage>
          <div>
            <h1>1in9.net</h1>
            <span>Network Gallery</span>
          </div>
        </div>
        <Link to={"/gallery"} className={styles.heroCta}>
          Explore the Gallery
        </Link>
      </figure>
    </Layout>
  );
};

export default IndexPage;
