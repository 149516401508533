import { graphql, Link } from "gatsby";
import * as React from "react";
import Seo from "../components/seo";
import Layout from "../layout/default";

import * as styles from "./blog-overview.module.scss";

const BlogIndexPage = ({data, pageContext}) => {
  return (
    <Layout>
      <Seo title={"Blog"+(pageContext.cat ? ": "+data.category.name : "")} description={pageContext.cat ? data.category.description : "Get the newest posts from the network gallery."} />
      <div className={styles.layoutBox}>
        <h1>Blog{pageContext.cat && ": "+data.category.name}</h1>

        {pageContext.cat && <p className={styles.description}>{data.category.description}</p>}

        <div className={styles.postList}>
          {data.allFile.nodes.map(({childMdx}) => {
            return <Link to={"/blog/"+childMdx.frontmatter.pathDate+"/"+childMdx.frontmatter.slug} key={childMdx.frontmatter.pathDate+"@"+childMdx.frontmatter.slug} className={styles.post}>
              <span className={styles.postTitle}>{childMdx.frontmatter.title}</span>
              <span className={styles.postMeta}>By {childMdx.frontmatter.author.name} @ {childMdx.frontmatter.date} | ~ {childMdx.timeToRead} min read</span>
              <div className={styles.postCats}>{childMdx.frontmatter.categories.map((cat) => {
                return <Link to={"/blog/c/"+cat.slug}>{cat.name}</Link>;
              })}</div>
              <p className={styles.postExcerpt}>{childMdx.excerpt}</p>
            </Link>;
          })}
          {data.allFile.nodes.length === 0 && <div className={styles.post}>
              <span className={styles.postTitle}>No Content</span>
              <span className={styles.postMeta}>There were no posts matching your criteria.</span>
            </div>}
        </div>

        <div className={styles.pageSwitcher}>
          {pageContext.previousPagePath ? <Link to={pageContext.previousPagePath}>Previous</Link> : <span />}
          <span>Page {pageContext.humanPageNumber} / {pageContext.numberOfPages}</span>
          {pageContext.nextPagePath ? <Link to={pageContext.nextPagePath}>Next</Link> : <span />}
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!, $cat: String) {
    allFile(
      filter: {
        sourceInstanceName: {eq: "blog"},
        childMdx: {
          body: {ne: null},
          frontmatter: {categories: {elemMatch: {slug: {eq: $cat}}}}
          }
        }
      skip: $skip
      limit: $limit,
      sort: {fields: childMdx___frontmatter___timestamp, order: DESC}
    ) {
      nodes {
        childMdx {
          excerpt
          timeToRead
          frontmatter {
            slug
            pathDate: timestamp(formatString: "YYYY/MM/DD")
            date: timestamp(formatString: "DD.MM.YYYY")
            title
            author {
              id
              name
            }
            categories {
              slug
              name
            }
          }
        }
      }
    }
    category: categoriesJson(slug: {eq: $cat}) {
      name
      description
    }
  }
`

export default BlogIndexPage;
