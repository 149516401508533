import { graphql, Link } from "gatsby";
import { ExternalLink, File, Folder, Image } from "lucide-react";
import * as React from "react";
import Layout from "../layout/default";

import * as styles from "./gallery.module.scss";

const GalleryPage = ({pageContext, data}) => {
  const parts = pageContext.relativePath.split("/");

  const partPaths = [];
  let breadcrumbs = "";

  for(let partI in parts) {
    const part = parts[partI];

    breadcrumbs += "/" + part;

    partPaths.push(breadcrumbs);
  }


  return (
    <Layout>
      <div className={styles.layoutBox}>
        {pageContext.relativePath !== "" && <span className={styles.breadcrumbs}><Link to={"/gallery"}>gallery</Link> / {parts.map((part, i) => {
          if(part === "") return null;

          if(i + 1 === parts.length) return null;

          return <><Link to={"/gallery"+partPaths[i]}>{part}</Link> / </>
        })}</span>}
        <h1>{pageContext.relativePath !== "" ? (pageContext.name ?? (pageContext.relativePath.split("/").pop())) : "Gallery"}</h1>

        <div className={styles.fileList}>
          <div className={styles.fileListHeader}>
            <div className={styles.icon}></div>
            <div className={styles.fileName}>Name</div>
            <div className={styles.fileType}>Type</div>
            <div className={styles.fileSize}>Size</div>
            <div className={styles.fileChange}>Last Change</div>
          </div>
          <div className={styles.fileListFolders}>
            {
              data.allDirectory.nodes.map((dir) => {
                return <Link to={"/gallery/"+dir.relativePath} className={styles.fileEntry}>
                  <div className={styles.icon}>
                    <Folder/>
                  </div>
                  <div className={styles.fileName}>{dir.name}</div>
                  <div className={styles.fileType}>Dir</div>
                  <div className={styles.fileSize}>-</div>
                  <div className={styles.fileChange}>-</div>
                </Link>
              })
            }
          </div>
          <div className={styles.fileListFiles}>
            {
              data.allFile.nodes.map((file) => {
                if(file.name.startsWith(".")) return null;

                if(file.name.endsWith(".link") && file.extension === "json") {
                  const data = JSON.parse(file.internal.content)

                  const nameExp = file.name.split(".")
                  nameExp.pop()
                  const name = nameExp.join(".")

                  return <a href={data.href} target={data.target} rel="noreferrer" className={styles.fileEntry}>
                    <div className={styles.icon}>
                      <ExternalLink/>
                    </div>
                    <div className={styles.fileName}>{name}<span>.link</span></div>
                    <div className={styles.fileType}>Weblink</div>
                    <div className={styles.fileSize}>{file.size}</div>
                    <div className={styles.fileChange}>{file.changeTime}</div>
                  </a>
                }

                return <a href={file.publicURL} className={styles.fileEntry}>
                  <div className={styles.icon}>
                    {
                      (() => {
                        switch(file.extension) {
                          case "png":
                            return <Image/>;

                          default:
                            return <File/>;
                        }
                      })()
                    }
                  </div>
                  <div className={styles.fileName}>{file.name}<span>.{file.extension}</span></div>
                  <div className={styles.fileType}>{file.extension}</div>
                  <div className={styles.fileSize}>{file.size}</div>
                  <div className={styles.fileChange}>{file.changeTime}</div>
                </a>
              })
            }
            {data.allDirectory.nodes.length === 0 && data.allFile.nodes.length === 0 && <span className={styles.fileEntry}>
                    <div className={styles.icon}>
                    </div>
                    <div className={styles.fileName}>No files.</div>
                    <div className={styles.fileType}>-</div>
                    <div className={styles.fileSize}>-</div>
                    <div className={styles.fileChange}>-</div>
                  </span> }
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($relativePath: String!) {
    allDirectory(
      filter: {sourceInstanceName: {eq: "gallery"}, relativeDirectory: {eq: $relativePath}}
    ) {
      nodes {
        name
        relativePath
        changeTime(fromNow: true)
      }
    }
    allFile(
      filter: {sourceInstanceName: {eq: "gallery"}, relativeDirectory: {eq: $relativePath}}
    ) {
      nodes {
        relativeDirectory
        relativePath
        publicURL
        changeTime(fromNow: true)
        extension
        name
        size: prettySize
        internal {
          content
        }
      }
    }
  }
`

export default GalleryPage;
