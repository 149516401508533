import * as React from "react";
import Navigation from "../components/navigation";
import Seo from "../components/seo";

import "../index.scss";
import * as styles from "./default.module.scss";

const LayoutDefault = ({ children }) => {
  return (
    <div className={styles.pageContainer}>
      <Seo></Seo>
      <Navigation></Navigation>
      <main>{children}</main>
      <footer>
        <a href="https://kevink.dev/legal/about" target="_blank" rel="noreferrer">
          Imprint
        </a>{" "}
        &middot;{" "}
        <a href="https://kevink.dev/legal/disclaimer" target="_blank" rel="noreferrer">
          Disclaimer
        </a>
      </footer>
    </div>
  );
};

export default LayoutDefault;
