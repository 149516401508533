// extracted by mini-css-extract-plugin
export var layoutBox = "gallery-module--layoutBox--FdbU2";
export var breadcrumbs = "gallery-module--breadcrumbs--tn-Dm";
export var fileList = "gallery-module--fileList--3YF+M";
export var fileEntry = "gallery-module--fileEntry--H0+zS";
export var fileListHeader = "gallery-module--fileListHeader--Q2Ad+";
export var icon = "gallery-module--icon--+Mbzk";
export var fileName = "gallery-module--fileName--RlEB9";
export var fileType = "gallery-module--fileType--7+Frm";
export var fileSize = "gallery-module--fileSize--NFldK";
export var fileChange = "gallery-module--fileChange--scq98";