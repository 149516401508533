import { graphql, Link } from "gatsby";
import * as React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../layout/default";

import * as styles from "./blog-post.module.scss";
import Seo from "../components/seo";

const BlogPost = ({data}) => {
  const mdx = data.file.childMdx;

  return (
    <Layout>
      <Seo title={mdx.frontmatter.title ? mdx.frontmatter.title : "Untitled"} article={true} description={mdx.excerpt} />
      <div className={styles.layoutBox}>
        <h1>{mdx.frontmatter.title ? mdx.frontmatter.title : "Untitled"}</h1>
        <span>By {mdx.frontmatter.author.name} @ {mdx.frontmatter.timestamp} in {mdx.frontmatter.categories.length > 0 ? mdx.frontmatter.categories.map((cat, i) => {
          return <><Link to={`/blog/c/${cat.slug}`}>{cat.name}</Link>{mdx.frontmatter.categories.length > i + 1 && ", "}</>;
        }) : "no category"}</span>

        <MDXRenderer>{mdx.body}</MDXRenderer>
      </div>
    </Layout>
  );
};

export const query = graphql`
query GetPost($slug: String!) {
  file(
    sourceInstanceName: {eq: "blog"},
    childMdx: {frontmatter: {slug: {eq: $slug}}}
  ) {
  childMdx {
      body
      excerpt
      frontmatter {
        author {
          name
        }
        slug
        timestamp(formatString: "DD.MM.YYYY HH:MM")
        title
        categories {
          name
          slug
        }
      }
      timeToRead
      wordCount {
        words
      }
    }
  }
}
`

export default BlogPost;
