// extracted by mini-css-extract-plugin
export var layoutBox = "index-module--layoutBox--mgrfm";
export var noScriptNotice = "index-module--noScriptNotice--dCVj9";
export var landingHero = "index-module--landingHero--g5NUx";
export var heroTextBox = "index-module--heroTextBox--9bnO9";
export var floatyEffect2 = "index-module--floatyEffect2--lnUyD";
export var unset = "index-module--unset--ccwJt";
export var heroTextBoxBg = "index-module--heroTextBoxBg--gL1L8";
export var heroCta = "index-module--heroCta--w+TED";
export var floatyImage1 = "index-module--floatyImage1---x8b2";
export var floatyEffect1 = "index-module--floatyEffect1--z4Xfk";
export var floatyImage2 = "index-module--floatyImage2--xqsGL";
export var floatyEffect3 = "index-module--floatyEffect3--YrzDL";